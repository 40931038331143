import React, { useEffect, useState } from "react";
import { Navigate, Link } from "react-router-dom";
import { database } from "./firebaseconfig";
import { ref, child, get, remove, set } from "firebase/database";
import "./dashboard.css"
import Navbar from "./Navbar";
import "./user.css"
function User() {
    const [key, setkey] = useState();
    const [popup, setpopup] = useState(false);
    const [data, setdata] = useState({});
    let [success, setSuccess] = useState(false);


    const dbRef = ref(database);
    useEffect(() => {
        get(child(dbRef, `users`)).then((snapshot) => {
            if (snapshot.exists()) {
                let value = snapshot.val();
                setdata(value);
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error("error comes");
        });
    });

    let token = localStorage.getItem("value");
    const deleteValue = (dataKey) => {
        setpopup(true);
        setkey(dataKey);
    }
    const checkValue = (event) => {
        event.preventDefault();
        console.log(event.target.value)
        if (event.target.value === "yes") {
            const dataRef = ref(database, `users/${key}`);
            remove(dataRef)
                .then(() => {
                    console.log('Data deleted successfully!');
                })
                .catch((error) => {
                    console.error('Error deleting data: ', error);
                });
        }
        setpopup(false);
    }

    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        const key = event.target.value;
        const valueRef = ref(database, `users/${key}/isdisabled`);
        if (checked) {
            set(valueRef, true)
                .then(() => {
                    setSuccess("true")
                })
                .catch(error => {
                    setSuccess("false")
                });
        }
        else{
            set(valueRef, false).then(() => {
                setSuccess("true")
            })
                .catch(error => {
                    setSuccess("false")
                });
        }
    }
    return (
        <>
            {
                token === null ? <>
                    <Navigate replace to="/adminlogin" />;
                </> :
                    <>
                        <Navbar />

                        <div class="container-fluid px-5 mt-4 pt-4">
                            {popup ? <div className="popup">
                                <p>Are you sure you want to delete this user ?</p>
                                <button onClick={checkValue} value="yes" className="btn btn-danger me-4">Yes</button>
                                <button onClick={checkValue} value="no" className="btn btn-secondary">No</button>
                            </div> : null}
                            {success === "true" ? (
                                <>
                                    <div className="alert alert-success" role="alert">
                                        User is updated successfully !
                                    </div>
                                </>
                            ) : null}
                            {success === "false" ? (
                                <>
                                    <div className="alert alert-danger" role="alert">
                                        There is some error. Please try again later !
                                    </div>
                                </>
                            ) : null}
                            <h1 class="container-title my-4 text-center"></h1>
                            <table>
                                <caption>Statement Summary</caption>
                                <thead>
                                    <tr className="headline">
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Company</th>
                                        <th scope="col">Link</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(data).map((key, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td data-label="Name">{data[key]?.name} {data[key]?.lastname}</td>
                                                    <td data-label="Email">{data[key]?.email}</td>
                                                    <td data-label="Amount">${data[key]?.amount}.00</td>
                                                    <td data-label="Company">{data[key]?.company}</td>
                                                    <td data-label="Gender">https://portal.infinitefunding.net/login/{key}</td>
                                                    {data[key]?.isdisabled ? <td data-label="Staus">Enable</td> : <td data-label="Staus">Disable</td>}
                                                    <div data-label="Operations" className="text-center btn-box">
                                                        <Link to={`/edit/${key}`}><button className="btn btn-primary mt-1 mx-4">Edit</button></Link>
                                                        <button onClick={() => {
                                                            deleteValue(key)
                                                        }} className="btn btn-danger mt-1">Delete</button>
                                                        <label class="switch mt-2">
                                                            <input type="checkbox"
                                                                checked={data[key]?.isdisabled}
                                                                onChange={handleCheckboxChange}
                                                                value={key} />
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
            }
        </>
    );
}
export default User;
