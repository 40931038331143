import React, { useState } from "react";
import Dashboard from "./Dashboard";
import RegistrationForm from "./RegistrationForm";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./Login";
import "./style.css";
import AdminLogin from "./AdminLogin";
import User from "./User";
import Edit from "./Edit";
import Error from "./Error";
function App() {
  const [dbData, setData] = useState({});

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Error />,
      errorElement: <Error />,
    },
    {
      path: "dashboard/:uId",
      element: <Dashboard />,
      errorElement: <Error />,
    },
    {
      path: "/register",
      element: <RegistrationForm dbData={dbData} setData={setData} />,
      errorElement: <Error />,
    },
    {
      path: "login/:uId",
      element: <Login />,
      errorElement: <Error />,
    },
    {
      path: "user",
      element: <User />,
      errorElement: <Error />,
    },
    {
      path: "edit/:key",
      element: <Edit dbData={dbData} setData={setData} />,
      errorElement: <Error />,
    },
    {
      path: "adminlogin",
      element: <AdminLogin />,
      errorElement: <Error />,
    },
  ]);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
