import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { database } from "./firebaseconfig";
import { ref, set, push, get, child } from "firebase/database";
import "./register.css";
import Navbar from "./Navbar";
function RegistrationForm({ dbData, setData }) {

  let token = localStorage.getItem("value");
  const [url, setUrl] = useState("");
  const [success, setsuccess] = useState("");
  const [error, seterror] = useState("");
  let [state, setState] = useState({
    name: "",
    lastname: "",
    email: "",
    company: "",
    amount: "",
    password: "",
    gender: "",
  });


  useEffect(() => {
    const dbRef = ref(database);
    get(child(dbRef, `users`)).then((snapshot) => {
      if (snapshot.exists()) {
        let data = snapshot.val();
        setData(data);
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
      console.error("error comes");
    });
  });



  const setValues = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setState((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  async function writeUserData(name, lastname, email, company, amount, gender, password) {
    let bigValue = Number(amount);
    let formattedValue = String(bigValue.toLocaleString());
    // amount = formattedValue + ".00";

    email = email.toLowerCase();
    const postListRef = ref(database, 'users/');
    const newPostRef = await push(postListRef);
    await set(newPostRef, {
      name: name,
      lastname: lastname,
      email: email,
      company,
      amount: formattedValue,
      gender,
      password,
      isdisabled: true
    });
  }


  const submitForm = async (event) => {
    event.preventDefault();
    const { name, lastname, email, company, amount, gender, password } = state;
    if (email !== "" && name !== "" && lastname !== "" && company !== "" && amount !== "" && gender !== "" && password !== "") {
      let flag = 0;
      Object.keys(dbData).map((key, index) => {
        if (dbData[key]?.email === email) {
          flag = -1;
        }
      })
      if (flag != -1) {
        await writeUserData(name, lastname, email, company, amount, gender, password);

        const dbRef = ref(database);
        get(child(dbRef, `users`)).then((snapshot) => {
          if (snapshot.exists()) {
            let data = snapshot.val();
            const [foundKey] = Object.entries(data).find(([key, user]) => user.email === email) || [];
            setUrl(`https://portal.infinitefunding.net/login/${foundKey}`)
          } else {
            console.log("No data available");

          }
        }).catch((error) => {
          console.error("error comes");
        });

        setsuccess("true");
      }
      else {
        setsuccess("false");
        seterror("Email already exist");
      }
    }
    else {
      setsuccess("false");
      seterror("Some Input field is empty please fill all the field");
    }
  };
  return (
    <>
      {
        token === null ? <>
          <Navigate replace to="/adminlogin" />;
        </> :
          <>
            <Navbar />
            <div class="form_wrapper">
              <div class="form_container">
                {success === "true" ? (
                  <>
                    <div class="alert alert-primary" role="alert">
                      <p>You have successfully registered a user</p>
                      <p>Here is the Url</p>
                      <p>{url}</p>
                      
                    </div>
                  </>
                ) : null}
                {success === "false" ? (
                  <>
                    <div class="alert alert-danger" role="alert">
                      {error}
                    </div>
                  </>
                ) : null}
                <div class="title_container">
                  <h2>Register User</h2>
                </div>
                <div class="row clearfix">
                  <div class="">
                    <form>
                      <div class="row clearfix">
                        <div class="col_half">
                          <div class="input_field">
                            {" "}
                            <span>
                              <i aria-hidden="true" class="fa fa-user"></i>
                            </span>
                            <input
                              type="text"
                              onChange={setValues}
                              value={state.name}
                              name="name"
                              placeholder="First Name"
                            />
                          </div>
                        </div>
                        <div class="col_half">
                          <div class="input_field">
                            {" "}
                            <span>
                              <i aria-hidden="true" class="fa fa-user"></i>
                            </span>
                            <input
                              onChange={setValues}
                              value={state.lastname}
                              type="text"
                              name="lastname"
                              placeholder="Last Name"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="input_field">
                        {" "}
                        <span>
                          <i aria-hidden="true" class="fa fa-envelope"></i>
                        </span>
                        <input
                          onChange={setValues}
                          value={state.email}
                          type="email"
                          name="email"
                          placeholder="Email"
                          required
                        />
                      </div>
                      {/* <div class="input_field">
                        {" "}
                        <span>
                          <i class="fa-solid fa-door-open"></i>
                        </span>
                        <input
                          onChange={setValues}
                          value={state.merchant}
                          type="text"
                          name="merchant"
                          placeholder="Merchant Name"
                          required
                        />
                      </div> */}
                      <div class="input_field">
                        {" "}
                        <span>
                          <i class="fa-sharp fa-solid fa-building"></i>
                        </span>
                        <input
                          onChange={setValues}
                          value={state.company}
                          type="text"
                          name="company"
                          placeholder="Company Name"
                          required
                        />
                      </div>
                      <div class="input_field input-balance">
                        {" "}
                        <span>
                          <i class="fa-solid fa-money-bill"></i>
                        </span>
                        <input
                          onChange={setValues}
                          value={state.amount}
                          type="number"
                          name="amount"
                          // min="1"
                          placeholder="Enter Amount in $"
                        />
                        <div className="amount">$</div>
                      </div>
                      <div class="input_field">
                        {" "}
                        <span>
                          <i aria-hidden="true" class="fa fa-lock"></i>
                        </span>
                        <input
                          onChange={setValues}
                          value={state.password}
                          type="password"
                          name="password"
                          placeholder="Password"
                          required
                        />
                      </div>
                      <div class="input_field radio_option">
                        <input
                          type="radio"
                          name="gender"
                          id="rd1"
                          onChange={setValues}
                          checked={state.gender === "male"}
                          value="male"
                        />
                        <label for="rd1">Male</label>
                        <input
                          type="radio"
                          name="gender"
                          id="rd2"
                          checked={state.gender === "female"}
                          onChange={setValues}
                          value="female"
                        />
                        <label for="rd2">Female</label>
                      </div>
                      {/* <div class="input_field select_option">
                  <select>
                    <option>Select a country</option>
                    <option>Option 1</option>
                    <option>Option 2</option>
                  </select>
                  <div class="select_arrow"></div>
                </div> */}
                      <div class="input_field checkbox_option">
                        <input type="checkbox" id="cb1" />
                        <label for="cb1">I agree with terms and conditions</label>
                      </div>
                      {/* <div class="input_field checkbox_option">
                        <input
                          type="checkbox"
                          id="cb2"
                          name="checkbox"
                          onChange={setValues}
                          onClick={changeCheckedValue}
                          value={checkedValue}
                          checked={(state.checkbox = checkedValue)}
                        />
                        <label for="cb2">I want to receive the newsletter</label>
                      </div> */}
                      <input
                        onClick={submitForm}
                        class="button"
                        type="submit"
                        value="Register"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <p class="credit">
              Developed by{" "}
              <a href="http://www.madeviser.com" target="_blank">
                Madeviser
              </a>
            </p> */}
          </>
      }
    </>
  );
}

export default RegistrationForm;
