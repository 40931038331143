import React from "react";
import "./register.css"
import { useNavigate, Link, Navigate } from "react-router-dom";
import app from "./firebaseconfig";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { LC2 } from "../assets/images/index";
import { useEffect } from "react";
function Navbar() {
    const navigate = useNavigate();
    const auth = getAuth(app);
    let token = localStorage.getItem("value");
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {

            } else {
                localStorage.removeItem("value");
                token = null;
                navigate("/adminlogin");
            }
        });
    })
    const logout = () => {
        signOut(auth).then(function () {
            localStorage.removeItem("value");
            token = null;
            navigate("/adminlogin");
            // Logout successful
        }).catch(function (error) {
            // An error occurred while logging out
            console.error(error);
        });
    }
    return (
        <>
            <nav class="navbar navbar-expand-lg">
                {/* <div className="row navbar-logo-img"> */}
                    <img className="navbar-logo-img" src={LC2} alt="" srcset="" />
                {/* </div> */}
                <button className="navbar-toggler toggle_btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <div class="navbar-nav">
                        <Link class="nav-item nav-link active" to="/register">Home <span class="sr-only">(current)</span></Link>
                        <Link class="nav-item nav-link" to="/user">All Users</Link>
                        <Link onClick={logout} class="nav-item nav-link" to="#">Logout</Link>
                        {/* <a class="nav-item nav-link disabled" href="#">Disabled</a> */}
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
