import React, { useState, useEffect } from "react";
import { useNavigate, Link, Navigate, useParams } from "react-router-dom";
import { database } from "./firebaseconfig";
import { ref, set, get, child } from "firebase/database";
import "./register.css";
import Navbar from "./Navbar";
function Edit({ dbData }) {
    const [uid, setuid] = useState();
    const navigate = useNavigate();
    let token = localStorage.getItem("value");
    let [state, setState] = useState({ name: "", lastname: "", email: "", company: "", amount: "", password: "", gender: "" });
    const [success, setsuccess] = useState("");
    const [error, seterror] = useState("");
    const setValues = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setState((prevValue) => {
            return {
                ...prevValue,
                [name]: value,
            };
        });
    };

    const { key } = useParams();
    useEffect(() => {
        const dbRef = ref(database);
        setuid(key)
        get(child(dbRef, `users/${uid}`)).then((snapshot) => {
            if (snapshot.exists()) {
                let data = snapshot.val();
                let newamount = data.amount.replace(",", "");
                // newamount = newamount.replace(".","");
                // console.log(newamount)
                setState({
                    name: data.name,
                    lastname: data.lastname,
                    email: data.email,
                    gender: data.gender,
                    company: data.company,
                    amount: newamount,
                    password: data.password
                })
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
            console.error("error comes");
        });
    }, [1, uid]);



    function writeUserData(name, lastname, email, company, amount, gender, password) {
        let bigValue = Number(amount);
        let formattedValue = String(bigValue.toLocaleString());
        // amount = formattedValue + ".00";
        email = email.toLowerCase();
        set(ref(database, "users/" + uid), {
            name: name,
            lastname: lastname,
            email: email,
            company,
            amount: formattedValue,
            gender,
            password
        })
            .then(() => {
                setsuccess("true");
            })
            .catch(() => {
                setsuccess("false");
                seterror("Something is wrong");
            })
    }
    const submitForm = async (event) => {
        event.preventDefault();
        const { name, lastname, email, company, amount, gender, password } = state;
        if (email !== "" && name !== "" && lastname !== "" && company !== "" && amount !== "" && gender !== "" && password !== ""
        ) {
            let flag = 0;
            Object.keys(dbData).map((key, index) => {
                if (dbData[key]?.email === email && key !== uid) {
                    flag = -1;
                }
            })
            if (flag != -1) {
                writeUserData(name, lastname, email, company, amount, gender, password);
            }
            else {
                setsuccess("false");
                seterror("Email already exist");
            }
        }
        else {
            setsuccess("false");
            seterror("Some Input field is empty please fill all the field")
        }
    };
    return (
        <>
            {
                token === null ? <>
                    <Navigate replace to="/adminlogin" />;
                </> :
                    <>
                        <Navbar />
                        <div class="form_wrapper">
                            <div class="form_container">
                                {success === "true" ? (
                                    <>
                                        <div class="alert alert-primary" role="alert">
                                            You have successfully Update a user
                                        </div>
                                    </>
                                ) : null}
                                {success === "false" ? (
                                    <>
                                        <div class="alert alert-danger" role="alert">
                                            {error}
                                        </div>
                                    </>
                                ) : null}
                                <div class="title_container">
                                    <h2>Register User</h2>
                                </div>
                                <div class="row clearfix">
                                    <div class="">
                                        <form>
                                            <div class="row clearfix">
                                                <div class="col_half">
                                                    <div class="input_field">
                                                        {" "}
                                                        <span>
                                                            <i aria-hidden="true" class="fa fa-user"></i>
                                                        </span>
                                                        <input
                                                            type="text"
                                                            onChange={setValues}
                                                            value={state.name}
                                                            name="name"
                                                            placeholder="First Name"
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col_half">
                                                    <div class="input_field">
                                                        {" "}
                                                        <span>
                                                            <i aria-hidden="true" class="fa fa-user"></i>
                                                        </span>
                                                        <input
                                                            onChange={setValues}
                                                            value={state.lastname}
                                                            type="text"
                                                            name="lastname"
                                                            placeholder="Last Name"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input_field">
                                                {" "}
                                                <span>
                                                    <i aria-hidden="true" class="fa fa-envelope"></i>
                                                </span>
                                                <input
                                                    onChange={setValues}
                                                    value={state.email}
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    required
                                                />
                                            </div>
                                            <div class="input_field">
                                                {" "}
                                                <span>
                                                    <i class="fa-sharp fa-solid fa-building"></i>
                                                </span>
                                                <input
                                                    onChange={setValues}
                                                    value={state.company}
                                                    type="text"
                                                    name="company"
                                                    placeholder="Company Name"
                                                    required
                                                />
                                            </div>
                                            <div class="input_field input-balance">
                                                {" "}
                                                <span>
                                                    <i class="fa-solid fa-money-bill"></i>
                                                </span>
                                                <input
                                                    onChange={setValues}
                                                    value={state.amount}
                                                    type="number"
                                                    name="amount"
                                                    // min="1"
                                                    placeholder="Enter Amount in $"
                                                />
                                                <div className="amount">$</div>
                                            </div>
                                            <div class="input_field">
                                                {" "}
                                                <span>
                                                    <i aria-hidden="true" class="fa fa-lock"></i>
                                                </span>
                                                <input
                                                    onChange={setValues}
                                                    value={state.password}
                                                    type="password"
                                                    name="password"
                                                    placeholder="Password"
                                                    required
                                                />
                                            </div>
                                            <div class="input_field radio_option">
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    id="rd1"
                                                    onChange={setValues}
                                                    checked={state.gender === "male"}
                                                    value="male"
                                                />
                                                <label for="rd1">Male</label>
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    id="rd2"
                                                    checked={state.gender === "female"}
                                                    onChange={setValues}
                                                    value="female"
                                                />
                                                <label for="rd2">Female</label>
                                            </div>

                                            <div class="input_field checkbox_option">
                                                <input type="checkbox" id="cb1" />
                                                <label for="cb1">I agree with terms and conditions</label>
                                            </div>
                                            <input
                                                onClick={submitForm}
                                                class="button"
                                                type="submit"
                                                value="Update"
                                            />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <p class="credit">
                            Developed by{" "}
                            <a href="http://www.madeviser.com" target="_blank">
                                Madeviser
                            </a>
                        </p> */}
                    </>
            }
        </>
    );
}

export default Edit;
