import { getDatabase } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyBpYyUO5KlyGlp6ZcT4sQ6ccEkbL9p47Fo",
  authDomain: "infinite-funding.firebaseapp.com",
  projectId: "infinite-funding",
  storageBucket: "infinite-funding.appspot.com",
  messagingSenderId: "392856478215",
  appId: "1:392856478215:web:ff2b4c8034c745164380a2",
  databaseURL: "https://infinite-funding-default-rtdb.asia-southeast1.firebasedatabase.app",
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);
export { database };
export default app;