import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { database } from "./firebaseconfig";
import { ref, set, get, child } from "firebase/database";
import "./register.css";
import ClipLoader from "react-spinners/ClipLoader";
import { LC2 } from "../assets/images/index";
import { useParams } from 'react-router-dom';
import Error from "./Error";

function Login() {

  const { uId } = useParams();
  const navigate = useNavigate();
  const [dbData, setData] = useState({});
  let [state, setState] = useState({
    email: "",
    password: "",
    checkbox: false,
  });
  let [loading, setLoading] = useState(true);
  const [success, setsuccess] = useState("");
  const [error, seterror] = useState("");
  const [errorPage, seterrorPage] = useState("");
  useEffect(() => {
    const dbRef = ref(database);
    get(child(dbRef, `users/${uId}`)).then((snapshot) => {
      if (snapshot.exists()) {
        let data = snapshot.val();
        if (data?.isdisabled) {
          setData(data);
          seterrorPage("false")
        }
        else
          seterrorPage("true")
      } else {
        console.log("No data available");
        seterrorPage("true");
      }
      setLoading(false);
    }).catch((error) => {
      console.error(error);
      console.error("error comes");
    });
  });


  const setValues = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setState((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };


  const submitSignInForm = (event) => {
    event.preventDefault();
    let { email, password } = state;
    email = email.toLowerCase();
    if (email !== "" && password !== "") {
      let flag = 0;
      if (dbData?.email === email && dbData?.password === password) {
        flag = -1;
        localStorage.setItem("user", JSON.stringify(dbData));
      }
      if (flag === -1) {
        localStorage.setItem("value", "true");
        navigate(`/dashboard/${uId}` , { replace: true });
      }
      else {
        setsuccess("false");
        seterror("Email or Password is wrong");
      }
    }
    else {
      setsuccess("false");
      seterror("Some Input field is empty please fill all the field");
    }
    // {
    //   let str = error.message;
    //   str = str.replace("Firebase:", "");
    //   str = str.replace("auth/", "");
    //   seterror(str);
    //   setsuccess("false");
    // }
  };
  return (
    <>
      {loading ?
        <div className="loader">
          <ClipLoader
            color="#000"
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div> : <>
          {errorPage === "true" ? <Error /> :
            <>
              <div className="container-fluid logo-box">
                <div className="row logo-img">
                  <img src={LC2} alt="" srcset="" />
                </div>
              </div>
                  <h2 className="text-center my-4">Welcome {dbData?.name} 😃</h2>
              <div class="form_wrapper">
                <div class="form_container">
                  {success === "false" ? (
                    <>
                      <div class="alert alert-danger" role="alert">
                        {error}
                      </div>
                    </>
                  ) : null}
                  <div class="title_container">
                    <h2>Portal Login </h2>
                  </div>
                  <div class="row clearfix">
                    <div class="">
                      <form>
                        <div class="input_field">
                          {" "}
                          <span>
                            <i aria-hidden="true" class="fa fa-envelope"></i>
                          </span>
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            onChange={setValues}
                            value={state.email}
                            required
                          />
                        </div>
                        <div class="input_field">
                          {" "}
                          <span>
                            <i aria-hidden="true" class="fa fa-lock"></i>
                          </span>
                          <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={setValues}
                            value={state.password}
                            required
                          />
                        </div>
                        <input class="button" onClick={submitSignInForm} type="submit" value="Login" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>}
        </>}
    </>
  );
}

export default Login;
