import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import app from "./firebaseconfig";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "./register.css";
import { LC2 } from "../assets/images/index";
function AdminLogin() {
    const navigate = useNavigate();
    const savepassword = process.env.REACT_APP_PASSWORD;
    const saveemail = process.env.REACT_APP_EMAIL;
    const [state, setState] = useState({
        email: "",
        password: ""
    });
    const [success, setsuccess] = useState("");
    const [error, seterror] = useState("");
    const auth = getAuth(app);
    const setValues = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setState((prevValue) => {
            return {
                ...prevValue,
                [name]: value,
            };
        });
    };
    const submitSignInForm = (event) => {
        event.preventDefault();
        let { email, password } = state;
        email = email.toLowerCase();

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                if (email === saveemail && password === savepassword) {
                    localStorage.setItem("value", "true");
                    navigate("/register");
                }
                else {
                    seterror("Your username or password is wrong");
                    setsuccess("false");
                }
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorMessage)
                seterror("Your username or password is wrong");
                setsuccess("false");
            });

    };
    return (
        <>
            <div className="container-fluid logo-box">
                <div className="row logo-img">
                    <img src={LC2} alt="" srcSet="" />
                </div>
            </div>
            <div className="form_wrapper">
                <div className="form_container">
                    {success === "false" ? (
                        <>
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        </>
                    ) : null}
                    <div className="title_container">
                        <h2>Admin Pannel</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="">
                            <form>
                                <div className="input_field">
                                    {" "}
                                    <span>
                                        <i aria-hidden="true" className="fa fa-envelope"></i>
                                    </span>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        onChange={setValues}
                                        value={state.email}
                                        required
                                    />
                                </div>
                                <div className="input_field">
                                    {" "}
                                    <span>
                                        <i aria-hidden="true" className="fa fa-lock"></i>
                                    </span>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        onChange={setValues}
                                        value={state.password}
                                        required
                                    />
                                </div>
                                <input className="button" onClick={submitSignInForm} type="submit" value="Login" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <p className="credit">
                Developed by{" "}
                <a href="http://www.madeviser.com" target="_blank">
                    Madeviser
                </a>
            </p> */}
        </>
    );
}

export default AdminLogin;
